@import '../../node_modules/bootstrap/scss/bootstrap.scss';

$font-primary: 'Work Sans',
Arial,
sans-serif;

$white: #fff;
$black: #000;
$darken: #151111;

// $primary: #26baee;
$soap: #CBBAED;
$saftyOrange: #fd6b00;
$electricBlue: #51e5ff;
$turqoiseBlue: #01FDF6;
$springGreen: #03FCBA;

$primary: #3a606e;
$secondary: $saftyOrange;
$thirdary: $springGreen;
$fourthary: $soap;

@mixin border-radius($radius) {
	border-radius: $radius;
}

@mixin transition($transition) {
	transition: all $transition ease;
}

@mixin gradient-background() {
	background: $primary;
	background: linear-gradient(45deg, $primary 0%, $soap 100%);
}

body {
	font-family: $font-primary;
	font-size: 18px;
	line-height: 1.8;
	font-weight: 400;
	color: lighten($black, 40%);

	&.menu-show {
		overflow: hidden;
		position: fixed;
		height: 100%;
		width: 100%;
	}
}

// span{
// 	color: lighten($black,75%);
// }
a {
	transition: .3s all ease;
	color: $secondary;

	&:hover,
	&:active,
	&:focus {
		color: $primary;
		outline: none !important;
		text-decoration: none !important;
	}
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
	line-height: 1.4;
	color: $black;
	font-weight: 400;
}

img {
	max-width: 100%;
	height: auto;
}

.text-primary {
	color: $primary !important;
}

.ftco-navbar-light {
	background: transparent !important;
	position: absolute;
	top: 0px;
	left: 0;
	right: 0;
	z-index: 3;
	padding: 0;

	@include media-breakpoint-down(md) {
		background: $primary !important;
		position: relative;
		top: 0;
		padding: 10px 15px;
	}

	.navbar-brand {
		width: 190px;
		@include media-breakpoint-down(md) {
			width: 115px;
		}
	}

	.navbar-nav {
		@include media-breakpoint-down(md) {
			padding-bottom: 10px;
		}

		>.nav-item {
			>.nav-link {
				font-size: 1.1em;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
				padding-left: 10px;
				padding-right: 10px;
				font-weight: 400;
				color: white;
				text-shadow: 0 0 2px black;

				&:hover {
					color: $white;
					background: $primary;
					text-shadow: none;
				}

				opacity: 1 !important;

				@include media-breakpoint-down(md) {
					padding-left: 0;
					padding-right: 0;
					padding-top: .9rem;
					padding-bottom: .9rem;
					color: $white;

					&:hover {
						color: $white;
					}
				}
			}

			.dropdown-menu {
				border: none;
				background: $white;
				box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);

				.dropdown-item {
					font-size: 14px;

					&:hover,
					&:focus {
						background: transparent;
						color: $black;
					}
				}
			}


			&.ftco-seperator {
				position: relative;
				margin-left: 20px;
				padding-left: 20px;

				@include media-breakpoint-down(md) {
					padding-left: 0;
					margin-left: 0;
				}

				&:before {
					position: absolute;
					content: "";
					top: 10px;
					bottom: 10px;
					left: 0;
					width: 2px;
					background: rgba($white, .05);

					@include media-breakpoint-down(md) {
						display: none;
					}
				}
			}

			&.cta {
				>a {
					color: $black;

					@include media-breakpoint-down(sm) {
						padding-left: 15px;
						padding-right: 15px;
					}

					@include media-breakpoint-down(md) {
						color: $white;
						background: $primary;
					}
				}
			}

			&.active {
				>a {
					color: $secondary;

					&:after {
						opacity: 1;
						background: $primary;
					}

				}
			}
		}
	}

	.navbar-toggler {
		border: none;
		color: $white;
		cursor: pointer;
		padding-right: 0;
		text-transform: uppercase;
		font-size: 16px;
		letter-spacing: .1em;

		&:focus {
			outline: none !important;
		}
	}

	&.scrolled {
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		margin-top: -130px;
		background: $primary !important;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);

		.nav-item {
			>.nav-link {
				&:hover {
					color: $black !important;
					background: $white;
				}
			}

			&.active {
				>a {
					color: $secondary !important;
					background: $white;
				}
			}

			&.cta {
				>a {
					color: $white !important;
					background: $primary;
					border: none !important;

					span {
						display: inline-block;
						color: $white !important;
					}
				}

				&.cta-colored {
					span {
						border-color: $primary;
					}
				}
			}
		}

		.navbar-nav {
			@include media-breakpoint-down(md) {
				background: none;
				border-radius: 0px;
				padding-left: 0rem !important;
				padding-right: 0rem !important;
			}
		}

		.navbar-nav {
			@include media-breakpoint-down(sm) {
				background: none;
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}

		.navbar-toggler {
			border: none;
			color: $white;
			cursor: pointer;
			padding-right: 0;
			text-transform: uppercase;
			font-size: 16px;
			letter-spacing: .1em;

		}

		.nav-link {
			padding-top: .9rem !important;
			padding-bottom: .9rem !important;
			color: $white !important;

			&.active {
				color: $secondary !important;
			}
		}

		&.awake {
			margin-top: 0px;
			transition: .3s all ease-out;
		}

		&.sleep {
			transition: .3s all ease-out;
		}

		.navbar-brand {
			color: $white;

			img {
				width: 130px;
			}
		}
	}
}

.navbar-brand {
	font-weight: 300;
	font-size: 22px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

//OWL CAROUSEL
.owl-carousel {
	position: relative;

	.owl-item {
		opacity: .4;

		img {
			height: 100%;
		}

		&.active {
			opacity: 1;
		}
	}

	.owl-nav {
		position: absolute;
		top: 50%;
		width: 100%;

		.owl-prev,
		.owl-next {
			position: absolute;
			transform: translateY(-50%);
			margin-top: -10px;
			color: $primary !important;
			@include transition(.7s);

			span {
				&:before {
					font-size: 30px;
				}
			}

			opacity: 0;
		}

		.owl-prev {
			left: 0;
		}

		.owl-next {
			right: 0;
		}
	}

	&:hover {
		.owl-nav {

			.owl-prev,
			.owl-next {
				opacity: 1;
			}

			.owl-prev {
				left: -25px;
			}

			.owl-next {
				right: -25px;
			}
		}
	}
}


.hero-wrap {
	width: 100%;
	position: relative;

	.slider-text {
		color: $white;

		.breadcrumbs {
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 1px;
			margin-bottom: 20px;
			z-index: 99;
			font-weight: 400;
			letter-spacing: 4px;

			span {
				color: rgba(0, 0, 0, .7);

				a {
					color: $black;
				}
			}
		}

		h1 {
			font-size: 70px;
			color: $black;
			font-weight: 200;

			span {
				color: $black;
			}

			@include media-breakpoint-down(md) {
				font-size: 45px;
			}
		}

		h2 {
			font-weight: 200;
		}

		.typewrite>.wrap {
			position: relative;
			border-right: 1px solid rgba(0, 0, 0, .1);
		}

		.bread {
			font-size: 70px;
			color: $black;
			line-height: 1.2;
			font-weight: 200;

			@include media-breakpoint-down(md) {
				font-size: 60px;
			}
		}

		.btn-primary {
			border: 1px solid rgba(255, 255, 255, .4);
			font-size: 1.4em;

			&:hover,
			&:focus {
				background: $white !important;
				color: $black;
			}
		}
	}

}

.textBlock-section {
	.embed-responsive {
		width: 100%;
		padding-top: 56.25%;
		height: 0px;
		position: relative;

		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.nap-list {
		// 		background-image: linear-gradient(90deg, $saftyOrange, $primary);
		// 		background-size: cover;
		// background-position: center;
		// background-attachment: fixed;
		height: 100%;
		list-style: none;
		margin: 0;

		li {
			margin: 0 0 1em 1.5em;
			position: relative;

			i {
				position: absolute;
				left: -1.5em;
				top: 0.5em;
				color: $saftyOrange;
			}
		}
	}
}

@keyframes blink {
	0% {
		opacity: 0;
	}

	50% {
		opacity: .5;
	}

	100% {
		opacity: 1;
	}
}


//USEFULL CODES

.bg-light {
	background: lighten($black, 98%) !important;
}

.bg-white {
	background: $white !important;
}

.ftco-no-pt {
	padding-top: 0 !important;
}

.ftco-no-pb {
	padding-bottom: 0 !important;
}

.bg-primary {
	background: $primary;
}


//BUTTON
.btn {
	cursor: pointer;
	@include border-radius(2px);
	box-shadow: none !important;
	font-size: 13px;

	&:hover,
	&:active,
	&:focus {
		outline: none;
	}

	&.btn-primary {
		background: lighten($primary, 0);
		border: 1px solid $primary !important;
		color: $white;

		&:hover {
			border: 1px solid $primary;
			background: transparent !important;
			color: $primary;
		}

		&.btn-outline-primary {
			border: 1px solid $primary;
			background: transparent;
			color: $primary;

			&:hover {
				border: 1px solid transparent;
				background: $primary;
				color: $white;
			}
		}
	}

	&.btn-white {
		background: $white;
		border: 1px solid $white;
		color: $black;

		&:hover {
			border: 1px solid $black;
			background: $black;
			color: $white;
		}

		&.btn-outline-white {
			border-color: rgba($white, .8);
			background: none;
			border-width: 1px;
			color: $white;

			&:hover,
			&:focus,
			&:active {
				background: $primary;
				border-color: $primary;
				color: $black;
			}
		}
	}

	&.btn-outline-black {
		border-color: rgba($black, 1);
		background: none;
		border-width: 1px;
		color: $black;

		&:hover,
		&:focus,
		&:active {
			background: $black;
			border-color: $black;
			color: $white;
		}
	}
}


//INTRO
.ftco-intro {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
}

.do-list {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
		font-size: 24px;
		margin-bottom: 15px;
		font-weight: 300;

		a {}

		i {
			color: $primary;
			font-size: 22px;
		}
	}
}


.ftco-gradient {
	@include gradient-background();

	.heading-section {
		.subheading {
			color: $white;
		}

		h2 {
			color: $white;
		}
	}
}

//GALLERY
.ftco-gallery {
	padding: 0;
}

.gallery {
	display: block;
	height: 270px;
	position: relative;

	.icon {
		width: 60px;
		height: 60px;
		margin: 0 auto;
		z-index: 0;
		opacity: 0;
		position: relative;
		@include transition(.6s);
		background: $primary;
		@include border-radius(50%);

		span {
			color: $white;
		}
	}

	&:hover,
	&:focus {
		.icon {
			opacity: 1;
		}
	}
}


//SERVICES
.services {
	width: 100%;
	display: block;
	margin-bottom: 40px;

	.icon {
		width: 100px;
		height: 100px;
		background: $primary;
		@include border-radius(50%);

		i {
			color: $white;
		}
	}

	.text {
		width: 100%;

		h3 {
			font-size: 20px;
			font-weight: 500;
		}
	}
}

.package-program {
	margin-bottom: 0;
	position: relative;
	z-index: 0;
	overflow: hidden;

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $primary;
		z-index: 0;
		content: '';
		opacity: 0;
		@include transition(.3s);

		@include media-breakpoint-down(md) {
			opacity: 0.5;
		}
	}

	.img {
		display: block;
		height: 400px;

		@include media-breakpoint-down(md) {
			height: auto;
		}
	}

	.text {
		z-index: 1;
		opacity: 0;
		@include transition(.2s);

		h3 {
			margin-bottom: 10px;
			font-size: 30px;
			font-weight: 500;
			color: $white;
		}

		color: rgba(255, 255, 255, .8);

		@include media-breakpoint-down(md) {
			opacity: 1;
		}
	}

	&:hover,
	&:focus {
		&:after {
			opacity: .5;
		}

		.text {
			opacity: 1;
		}
	}
}

.btn-custom {
	@include gradient-background();
	color: $white;
	display: block;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
	@include border-radius(2px);

	span {
		color: $white;
	}

	&:hover,
	&:focus {
		color: $white;
	}
}

//COACH
.coach {
	width: 100%;
	display: block;
	margin-bottom: 30px;

	.img,
	.text {
		width: 100%;
	}

	.img {
		height: 400px;
		background-position: top center !important;
	}

	.text {
		.subheading {
			font-size: 13px;
			color: rgba(0, 0, 0, .4);
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: 500;
		}

		h3 {
			font-size: 22px;
			color: $black;

			a {
				color: $black;
			}
		}

		.ftco-social-media {
			padding: 0;

			li {
				list-style: none;

				a {
					display: block;
					width: 40px;
					height: 40px;
					background: $primary;
					@include border-radius(50%);

					span {
						font-size: 24px;
						color: $white;
					}
				}
			}
		}
	}
}

//SCHEDULE
.sched-list {
	@include media-breakpoint-down(xl) {
		overflow-x: scroll;
	}
}

.table {
	min-width: 1000px !important;
	width: 100%;
	text-align: center;

	th {
		font-weight: 500;
	}

	.thead-primary {
		background: $primary;

		tr {
			th {
				padding: 20px 10px;
				color: $white !important;
				border: 1px solid transparent !important;
			}
		}
	}

	tbody {
		tr {
			td {
				text-align: center !important;
				vertical-align: middle;
				padding: 40px 10px;
				border: 1px solid transparent !important;

				// border-bottom: 1px solid rgba(255,255,255,.1) !important;
				h3 {
					margin-bottom: 20px;
					font-size: 16px;

					a {
						text-transform: uppercase;
						color: rgba(255, 255, 255, 1);
					}
				}

				.at {
					margin-bottom: 15px;
					display: block;
					color: rgba(255, 255, 255, .8);
				}

				.time {
					color: rgba(255, 255, 255, .8);
				}

				.trainer {
					color: $white;
				}

				&.color {
					background: $primary;
				}

				&.color-1 {
					background: #7acfdf;
				}

				&.color-2 {
					background: #26baee;
				}

				&.color-3 {
					background: #fb929e;
				}

				&.color-4 {
					background: #12e6c8;
				}

				&.color-5 {
					background: #a287f4;
				}
			}
		}
	}
}

//TESTIMONY
.testimony-section {
	position: relative;

	.owl-carousel {
		margin: 0;
	}

	.owl-carousel .owl-stage-outer {
		padding-bottom: 2em;
		position: relative;
	}

	.owl-nav {
		position: absolute;
		top: 100%;
		width: 100%;

		.owl-prev,
		.owl-next {
			position: absolute;
			// transform: translateY(-50%);
			// margin-top: -10px;
			outline: none !important;
			@include transition(.3s);

			span {
				&:before {
					font-size: 30px;
					color: rgba(0, 0, 0, .2);
					@include transition(.3s);
				}
			}

			&:hover,
			&:focus {
				span {
					&:before {
						color: $black;
					}
				}
			}

			opacity: 0;
		}

		.owl-prev {
			left: 50%;
			margin-left: -80px;
		}

		.owl-next {
			right: 50%;
			margin-right: -80px;
		}
	}

	&:hover {
		.owl-nav {

			.owl-prev,
			.owl-next {
				opacity: 1;
			}

			.owl-prev {
				left: 50%;
				margin-left: -80px;
			}

			.owl-next {
				right: 50%;
				margin-right: -80px;
			}
		}
	}

	.owl-dots {
		text-align: center;

		.owl-dot {
			width: 10px;
			height: 10px;
			margin: 5px;
			border-radius: 50%;
			background: rgba(0, 0, 0, .2);

			&.active {
				background: $primary;
			}
		}
	}
}

.testimony-wrap {
	display: block;
	position: relative;
	background: rgba(255, 255, 255, .1);
	color: rgba(0, 0, 0, .8);

	.user-img {
		width: 110px;
		height: 110px;
		border-radius: (50%);
		position: relative;
	}

	.name {
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 0;
		color: $black;
	}

	.position {
		font-size: 13px;
	}

	.line {
		position: relative;
		border-left: 1px solid lighten($black, 90%);
	}

	.quote {
		position: absolute;
		top: 50%;
		left: -20px;
		width: 40px;
		height: 40px;
		transform: translateY(-50%);
		background: $primary;
		@include border-radius(50%);

		i {
			color: $white;
		}
	}
}

.ftco-social {
	a {
		width: 30px;
		height: 30px;
		display: inline-block;
		margin-right: 2px;
		background: lighten($primary, 40%);
		@include border-radius(50%);

		span {
			color: $primary;
		}
	}
}




// USEFUL CODE
.form-control {
	height: 58px !important;
	background: $white !important;
	color: rgba(0, 0, 0, .9) !important;
	font-size: 18px;
	border-radius: 0px;
	box-shadow: none !important;
	border: transparent !important;
	border: 1px solid rgba(0, 0, 0, .08) !important;
	padding-right: 0;
	padding-left: 0;

	&:focus,
	&:active {
		border-color: $primary;
	}
}

textarea.form-control {
	height: inherit !important;
}


.ftco-animate {
	opacity: 0;
	visibility: hidden;
}

.bg-primary {
	background: $primary !important;
}




.ftco-section {
	padding: 4em 0;
	position: relative;

	@include media-breakpoint-down(sm) {
		padding: 2em 0;
	}
}

.ftco-bg-dark {
	background: #090808;
}


.ftco-footer {
	font-size: 16px;
	padding: 4em 0;
	background: $primary;

	h1.logo {
		color: $white;
		font-size: 30px;
		font-weight: 300;
	}

	h2.location {
		font-size: 24px;
		color: rgba(255, 255, 255, .9);
	}

	.ftco-footer-widget {
		ul {
			li {
				a {
					span {
						color: $white;
					}
				}
			}
		}
	}

	p {
		color: rgba($white, .7);
	}

	a {
		color: rgba($white, .7);

		&:hover {
			color: $white;
		}
	}
}


.ftco-footer-social {
	li {
		list-style: none;
		margin: 0 10px 0 0;
		display: inline-block;

		a {
		height: 3em;
			width: 3em;
			display: block;
			float: left;
			background: rgba($white, .05);
			border-radius: 50%;
			position: relative;

			span {
				position: absolute;
				font-size: 26px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:hover {
				color: $white;
			}
		}
	}
}

// Map
.map-section {
	padding: 0;
}

#map {
	width: 100%;
	height: 500px;

	@include media-breakpoint-down(md) {
		height: 300px;
	}
}


@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba($primary, 0.2);
	}

	70% {
		-webkit-box-shadow: 0 0 0 30px rgba($primary, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba($primary, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba($primary, 0.2);
		box-shadow: 0 0 0 0 rgba($primary, 0.2);
	}

	70% {
		-moz-box-shadow: 0 0 0 30px rgba($primary, 0);
		box-shadow: 0 0 0 30px rgba($primary, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba($primary, 0);
		box-shadow: 0 0 0 0 rgba($primary, 0);
	}
}

.subheading {
	font-size: 16px;
	display: block;
	margin-bottom: 5px;
	color: $secondary;
	line-height: 1;
	z-index: 1;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 3px;
}

.heading-section {
	z-index: 0;



	h2 {
		z-index: -1;
		font-size: 60px;
		font-weight: 300;
		position: relative;

		@include media-breakpoint-down(sm) {
			font-size: 28px;
		}
	}
}

//COVER BG
.hero-wrap,
.img,
.blog-img,
.user-img {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}


.img-about {
	@include media-breakpoint-down(sm) {
		height: 400px;
		margin-bottom: 30px;
	}
}


// magnific pop up

.image-popup {
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}




//COUNTER
#section-counter {
	position: relative;
	z-index: 0;
	background: $primary;

	// .overlay {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	// background: $black;
	// 	@include gradient-background();
	// 	opacity: .8;
	// }
}

.ftco-counter {
	padding: 7em 0;

	@include media-breakpoint-down(lg) {
		background-position: center center !important;
	}

	.icon {
		width: 80px;
		height: 80px;
		margin: 0 auto;
		position: relative;
		margin-bottom: 20px;
		z-index: 0;

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			bordeR: 1px solid $primary;
			z-index: -1;
			@include transition(.3s);
		}

		span {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			color: $primary;
		}
	}

	.text {
		strong.number {
			font-weight: 500;
			font-size: 50px;
			color: $white;
		}

		span {
			font-weight: 400;
			font-size: 14px;
			color: rgba(255, 255, 255, .7);
		}

		&:hover,
		&:focus {
			.icon {
				span {
					color: $black;
				}

				&:after {
					transform: rotate(135deg);
					background: $primary;
				}
			}
		}
	}

	.counter-wrap {
		@include media-breakpoint-down(md) {
			margin-bottom: 40px !important;
		}
	}

	.ftco-number {
		display: block;
		color: $white;
	}

	.ftco-label {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: .1em;
	}
}


//blocks 
.block-20 {
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	display: block;
	width: 100%;
	height: 270px;
}

.blog-entry {
	overflow: hidden;

	@include media-breakpoint-up(md) {
		margin-bottom: 30px;
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
	}

	.text {
		position: relative;
		margin-top: -40px;
		background: $white;
		width: 100%;

		.heading {
			font-size: 18px;
			margin-bottom: 16px;
			font-weight: 400;

			a {
				color: $black;

				&:hover,
				&:focus,
				&:active {
					color: $primary;
				}
			}
		}
	}

	.meta {
		>div {
			display: inline-block;
			margin-right: 5px;
			margin-bottom: 0;
			font-size: 14px;

			a {
				color: lighten($black, 50%);
				font-size: 15px;

				&:hover {
					color: lighten($black, 40%);
				}
			}
		}
	}

	.one {
		width: 80px;
	}

	.two {
		width: calc(100% - 80px);
	}

	span.day {
		font-size: 58px;
		font-weight: 300;
		color: $primary;
		line-height: 1;
	}

	span.yr,
	span.mos {
		display: block;
		color: lighten($black, 70%);
	}
}


.block-23 {
	ul {
		padding: 0;

		li {

			&,
			>a {
				display: table;
				line-height: 1.5;
				margin-bottom: 15px;
			}

			span {
				color: rgba($white, .7);
			}

			.icon,
			.text {
				display: table-cell;
				vertical-align: top;
			}

			.icon {
				width: 40px;
				font-size: 18px;
				padding-top: 2px;
				color: rgba($white, 1);
			}

		}
	}
}

.block-6 {
	.icon {
		span {
			&:before {}
		}
	}

	.media-body {
		.heading {}

		p {}
	}
}


//PRICING
.block-7 {
	margin-bottom: 30px;
	padding: 30px;
	background: $white;
	-webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
	@include transition(.3s);

	.heading {
		font-size: 14px;
		line-height: 1;
		margin: 0;
		padding: 0;
		font-weight: 500;
		margin-bottom: 10px;
		display: inline-block;
		text-transform: uppercase;

	}

	.heading-2 {
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 600;
	}

	.price {
		margin: 0;
		padding: 0;
		display: block;

		sup {
			font-size: 24px;
			top: -1em;
			color: $primary;
		}

		.number {
			font-size: 60px;
			font-weight: 500;
			color: $primary;
		}
	}

	.excerpt {
		margin-bottom: 20px;
		color: lighten($black, 60%);
	}

	.label2 {
		text-transform: uppercase;
	}

	.pricing-text {

		&,
		li {
			padding: 0;
			margin: 0;
		}

		li {
			list-style: none;
			margin-bottom: 15px;
			color: lighten($black, 30%);
		}
	}

	.btn-primary {
		color: $white;
		border: transparent;
		text-transform: uppercase;
		font-style: 16px;
		font-weight: 600;
		letter-spacing: 1px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 60%;
		margin: 0 auto;

		&:hover,
		&:focus {
			background: $primary !important;
			color: $white;
		}
	}

	&:hover,
	&:focus {
		-webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
		-moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
		box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
	}

}

//### .block-18 
.block-18 {

	.icon,
	.text {}

	.icon {
		>span {
			font-size: 40px;
		}
	}

	.text {
		strong {
			font-size: 30px;
		}

		span {
			display: block;
		}
	}
}


//PAGINATION
.block-27 {
	ul {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			margin-bottom: 4px;
			font-weight: 400;

			a,
			span {
				color: $primary;
				text-align: center;
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border-radius: 50%;
				border: 1px solid lighten($black, 90%);
			}

			&.active {

				a,
				span {
					background: $primary;
					color: $white;
					border: 1px solid transparent;
				}
			}
		}
	}
}

.contact-section {
	.contact-info {
		p {
			a {}

			span {
				color: $black;
			}
		}
	}
}


.contact-form {
	.form-group {
		position: relative;
	}

	.form-control {
		border: transparent !important;
		border-bottom: 1px solid rgba(0, 0, 0, .08) !important;
		height: 58px !important;
		padding-left: 0;
		padding-right: 0;
		background: transparent !important;
		color: rgba(0, 0, 0, .9) !important;
		font-size: 13px;
		border-radius: 0px;
		box-shadow: none !important;

		&::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			color: rgba(0, 0, 0, .9);
		}

		&::-moz-placeholder {
			/* Firefox 19+ */
			color: rgba(0, 0, 0, .9);
		}

		&:-ms-input-placeholder {
			/* IE 10+ */
			color: rgba(0, 0, 0, .9);
		}

		&:-moz-placeholder {
			/* Firefox 18- */
			color: rgba(0, 0, 0, .9);
		}

		&:focus,
		&:active {
			border-color: $primary !important;
		}
	}

	textarea.form-control {
		height: inherit !important;
	}
}


.block-9 {

	.form-control {
		outline: none !important;
		box-shadow: none !important;
		font-size: 15px;
	}

	#map {}
}


//### .block-21
.block-21 {
	.blog-img {
		display: block;
		height: 80px;
		width: 80px;
	}

	.text {
		width: calc(100% - 100px);

		.heading {
			font-size: 16px;

			a {
				color: $black;

				&:hover,
				&:active,
				&:focus {
					color: $primary;
				}
			}
		}

		.meta {
			>div {
				display: inline-block;
				font-size: 12px;
				margin-right: 5px;

				a {
					color: lighten($black, 50%);
				}
			}
		}
	}
}

.custom-pagination {
	width: 100%;
	text-align: center;
	display: inline-block;

	li {
		display: inline-block;
	}

	.prev,
	.next {
		a {
			font-size: 20px !important;
			line-height: 38px !important;
		}
	}

	li,
	.prev,
	.next {
		a {
			width: 40px;
			height: 40px;
			line-height: 40px;
			padding: 0;
			margin: 0;
			border-radius: 50% !important;
			font-size: 16px;
		}

		&.active {
			a {
				display: block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				padding: 0;
				margin: 0;
				border-radius: 50% !important;
				font-size: 16px;
				background: $primary;
				color: $white;

				&:hover,
				&:focus {
					color: $white;
				}
			}
		}
	}

	.prev {
		float: left;
	}

	.next {
		float: right;
	}
}

/* Blog*/
.post-info {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	color: $white;
	letter-spacing: .1em;

	>div {
		display: inline-block;

		.seperator {
			display: inline-block;
			margin: 0 10px;
			opacity: .5;
		}
	}
}

.tagcloud {
	a {
		text-transform: uppercase;
		display: inline-block;
		padding: 4px 10px;
		margin-bottom: 7px;
		margin-right: 4px;
		border-radius: 4px;
		color: $black;
		border: 1px solid lighten($black, 90%);
		font-size: 11px;

		&:hover {
			border: 1px solid #000;
		}
	}
}

.comment-form-wrap {
	clear: both;
}

.comment-list {
	padding: 0;
	margin: 0;

	.children {
		padding: 50px 0 0 40px;
		margin: 0;
		float: left;
		width: 100%;
	}

	li {
		padding: 0;
		margin: 0 0 30px 0;
		float: left;
		width: 100%;
		clear: both;
		list-style: none;

		.vcard {
			width: 80px;
			float: left;

			img {
				width: 50px;
				border-radius: 50%;
			}
		}

		.comment-body {
			float: right;
			width: calc(100% - 80px);

			h3 {
				font-size: 20px;
			}

			.meta {
				text-transform: uppercase;
				font-size: 13px;
				letter-spacing: .1em;
				color: #ccc;
			}

			.reply {
				padding: 5px 10px;
				background: lighten($black, 90%);
				color: $black;
				text-transform: uppercase;
				font-size: 11px;
				letter-spacing: .1em;
				font-weight: 400;
				border-radius: 4px;

				&:hover {
					color: $white;
					background: lighten($black, 0%);
				}
			}
		}
	}
}

//SIDEBAR SEARCH
.sidebar-wrap {
	padding: 20px;
	border: 1px solid lighten($black, 90%);
	margin-bottom: 30px;

	.heading {
		font-size: 18px;
	}

	.fields {
		width: 100%;
		position: relative;

		.form-control {
			box-shadow: none !important;
			border: transparent;
			background: $white !important;
			color: lighten($black, 30%) !important;
			border: 1px solid lighten($black, 90%);
			font-size: 14px;
			width: 100%;
			height: 52px !important;
			padding: 10px 20px;
			@include border-radius(0);

			&::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				color: lighten($black, 30%);
			}

			&::-moz-placeholder {
				/* Firefox 19+ */
				color: lighten($black, 30%);
			}

			&:-ms-input-placeholder {
				/* IE 10+ */
				color: lighten($black, 30%);
			}

			&:-moz-placeholder {
				/* Firefox 18- */
				color: lighten($black, 30%);
			}
		}

		.icon {
			position: absolute;
			top: 50%;
			right: 30px;
			font-size: 14px;
			transform: translateY(-50%);
			color: rgba($black, .7);

			@include media-breakpoint-down(sm) {
				right: 10px;
			}
		}

		.textfield-search,
		.select-wrap {}

		.textfield-search {
			input {}
		}

		.select-wrap {
			position: relative;

			select {
				appearance: none;
			}
		}
	}

	.form-group {
		.btn {
			width: 100%;
			display: block !important;
			@include border-radius(2px);
		}
	}
}

// sidebar

.sidebar-box {
	margin-bottom: 30px;
	padding: 25px;
	font-size: 15px;
	width: 100%;

	float: left;

	background: $white;

	*:last-child {
		margin-bottom: 0;
	}

	h3.heading-2 {
		font-size: 18px;
		margin-bottom: 30px;
	}
}

.categories,
.sidelink {
	li {
		position: relative;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid gray('300');
		list-style: none;

		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 0;
		}

		a {
			display: block;
			color: $black;

			span {
				position: absolute;
				right: 0;
				top: 0;
				color: #ccc;
			}
		}

		&.active {
			a {
				color: $black;
				font-style: italic;
			}
		}
	}
}


.search-form {
	background: lighten($black, 95%);
	padding: 10px;

	.form-group {
		position: relative;
		background: $white;

		input {
			padding-right: 50px;
			padding-left: 15px;
		}
	}

	.icon {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}
}

#ftco-loader {
	position: fixed;
	width: 96px;
	height: 96px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
	border-radius: 16px;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-out, visibility 0s linear .2s;
	z-index: 1000;
}

#ftco-loader.fullscreen {
	padding: 0;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transform: none;
	background-color: #fff;
	border-radius: 0;
	box-shadow: none;
}

#ftco-loader.show {
	transition: opacity .4s ease-out, visibility 0s linear 0s;
	visibility: visible;
	opacity: 1;
}

#ftco-loader .circular {
	animation: loader-rotate 2s linear infinite;
	position: absolute;
	left: calc(50% - 24px);
	top: calc(50% - 24px);
	display: block;
	transform: rotate(0deg);
}

#ftco-loader .path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: loader-dash 1.5s ease-in-out infinite;
	stroke-linecap: round;
}

@keyframes loader-rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes loader-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -136px;
	}
}